import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useWebSocket from 'react-use-websocket';
import { getServerLocation } from '../../../utils/serverLocation';
import ChatBox from './ChatBox';
import MobileChatbox from './MobileChatbox';
import { CHATBOT_NAME } from '../../../utils/constants';
import nudgeApi from '../../../apis/nudgeApi';

/**
 * Directly handles websocket/api logic and integrates it with ChatBox component
 */
function SocketChatbox({
  currentUser,
  userToSendTo,
  displayConnection = true,
  isMobile = false,
  token,
  displayMobileSendMessage = false,
  autoScrollBottom = true,
}) {
  const server = getServerLocation(true);
  const socketUrl = `${server}/messaging/chat/${token}`;
  const [messageHistory, setMessageHistory] = useState([]);
  const [messageIndex, setMessageIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialMessages, setInitialMessages] = useState([]);
  const [displayMobileChatbox, setDisplayChatbox] = useState(displayMobileSendMessage);

  const messagesPerApiCall = 50;
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
  });

  const enterMessage = (messageText) => {
    const message = {
      from: currentUser,
      to: userToSendTo || '',
      contents: [
        {
          type: 'text',
          message: messageText,
          timestamp: new Date().valueOf(),
        },
      ],
    };

    if (messageText.trim() !== '') {
      sendJsonMessage(message);
      setMessageHistory([...messageHistory, message]);
    }
  };

  const fetchPreviousMessages = async () => {
    // TODO: Uncomment when we actually have a need for message history in basil
    const encodedCurrentUser = encodeURIComponent(currentUser);
    const encodedRecipientData = encodeURIComponent(userToSendTo);
    const userToFetchFrom = isMobile ? encodedCurrentUser : encodedRecipientData;
    const result = [];
    const urlParam = `messaging/messages?app_id=nudge&user=${currentUser}&pageSize=${messagesPerApiCall}&pageIndex=${messageIndex}`;
    const pastMessages = await nudgeApi.get(`/${urlParam}`);
    if (pastMessages?.data?.data) {
      const messageArray = pastMessages.data.data;
      messageArray.reverse();
      if (messageArray.length !== 0) {
        result.push(...messageArray, ...messageHistory);
        setMessageIndex(messageIndex + 1);
        setMessageHistory(result);
        setInitialMessages(result);
      }
    }
  };

  useEffect(() => {
    fetchPreviousMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleReceivedMessage = async (receivedMessage) => {
    if (receivedMessage && receivedMessage.data) {
      let jsonError = false;
      let jsonMessage = '';
      try {
        jsonMessage = JSON.parse(receivedMessage.data);
      } catch (e) {
        jsonError = true;
      }

      if (!jsonError) {
        if (jsonMessage.from !== CHATBOT_NAME) {
          setMessageHistory((prev) => prev.concat(jsonMessage));
        } else {
          // Will split contents into separate messages if message is from bot
          const jsonMessageContent = jsonMessage.contents;
          const result = [];
          jsonMessageContent.forEach(async (content) => {
            const newContentMessage = { ...jsonMessage, contents: [content] };
            result.push(newContentMessage);
          });
          await setMessageHistory((prev) => prev.concat(...result));
        }
      }
    }
  };

  useEffect(() => {
    if (!isMobile) {
      handleReceivedMessage(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => { }, [messageHistory]);

  /**
   * Handles message scrolling for mobile and desktop chatboxes
   * @param {Element} e - Event from Message Container
   * @return {void}
   */
  const handleChatScroll = async (e) => {
    if (e.target.scrollTop === 0 && initialMessages.length !== 0) {
      const pastScroll = e.target.scrollHeight;
      await fetchPreviousMessages();
      // Element scroll height will change  when messages are fetched
      const currentScroll = e.target.scrollHeight - pastScroll;
      e.target.scrollTo(0, currentScroll);
    }
  };

  return isMobile ? (
    <MobileChatbox
      username={currentUser}
      messages={messageHistory}
      connectionState={readyState}
      displayConnection={displayConnection}
      mobileView={isMobile}
      sendJsonMessage={sendJsonMessage}
      setMessageHistory={setMessageHistory}
      handleChatScroll={handleChatScroll}
      autoScrollBottom={autoScrollBottom}
      loading={loading}
      setLoading={setLoading}
      fetchPreviousMessages={fetchPreviousMessages}
      lastMessage={lastMessage}
      displayMobileSendMessage={displayMobileSendMessage}
      token={token}
    />
  ) : (
    <ChatBox
      username={currentUser}
      onMessageSend={enterMessage}
      messages={messageHistory}
      connectionState={readyState}
      displayConnection={displayConnection}
      handleChatScroll={handleChatScroll}
    />
  );
}

SocketChatbox.propTypes = {
  // Integer representing socket connection status
  currentUser: PropTypes.string.isRequired,
  userToSendTo: PropTypes.string,
  displayConnection: PropTypes.bool,
  token: PropTypes.string,
  isMobile: PropTypes.bool,
  displayMobileSendMessage: PropTypes.bool,
  autoScrollBottom: PropTypes.bool,
};

export default SocketChatbox;
